<template>
  <div class="container oci-check-in-out-body">
    <div
      v-if="isLoadingBooking"
      class="oci-check-in-salutation"
    >
        Loading
    </div>  
    <div v-else>
      <div class="oci-check-in-out-heading-date">
        <div class="oci-map-header">
          <div class="float-left">
            <feather-icon
              id="notifications"
              icon="ChevronLeftIcon"
              size="24"
              @click="GoToBackProjectsListPage"
            />
          </div>
        </div>       
        {{ formatDate(currentBooking.booked_for) }}
      </div>

      <div :class="dynamicClass">
        <h2 class="mb-1">
          {{ Heading }}
        </h2>
        <h5>{{ subHeading }}</h5>
      </div>
      <AcceptReject />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BImg } from 'bootstrap-vue'
import AcceptReject from '@/views/components/DriverProcess.vue'
import store from '@/store'

export default {
  components: {
    AcceptReject,
    BImg,
  },
  computed: {
    ...mapGetters({ isLoadingBooking: 'app/getIsLoadingBooking' }),
    ...mapGetters({ userName: 'app/getUserName' }),
    ...mapGetters({ bookingID: 'app/getDriverBookingID' }),
    ...mapGetters({ currentBooking: 'app/getCurrentBookingData' }),

    Heading() {
      console.log('subHeading', this.currentBooking.status)
      if (this.currentBooking.status == 'to_do') {
        return 'To do'
      } else if (this.currentBooking.status == 'started'){
        return 'Started'
      } else if (this.currentBooking.status == 'completed'){
        return 'Completed'        
      } else if (this.currentBooking.status == 'rejected'){
        return 'Rejected'        
      } else if (this.currentBooking.status == 'cancelled'){
        return 'Cancelled'        
      } else {
        return 'Completed'        
      }      
    },
    subHeading() {
      console.log('subHeading', this.currentBooking.status)
      if (this.currentBooking.status == 'to_do') {
        return "You haven't started this job yet:"
      } else if (this.currentBooking.status == 'started'){
        return 'You have to completed this job:'
      } else if (this.currentBooking.status == 'completed'){
        return 'You have completed this job:'        
      } else if (this.currentBooking.status == 'rejected'){
        return 'You have rejected this job:'        
      } else if (this.currentBooking.status == 'cancelled'){
        return 'You have cancelled this job:'        
      } else {
        return 'You have completed this job:'        
      }      
    },
    dynamicClass() {
      console.log('dynamicClass', this.currentBooking.status)
      if (this.currentBooking.status == 'to_do') {
        return 'oci-offered-salutation';
      } else if (this.currentBooking.status == 'started'){
        return 'oci-accepted-salutation';
      } else if (this.currentBooking.status == 'completed'){
        return 'oci-completed-salutation';      
      } else if (this.currentBooking.status == 'rejected'){
        return 'oci-noshow-salutation';
      } else if (this.currentBooking.status == 'cancelled'){
        return 'oci-noshow-salutation';
      } else {
        return 'oci-completed-salutation';            
      }   
    },    
  },
  data() {
    return {
      jsonData: [],
      noJobsImg: require('@/assets/images/pages/rafiki.png'),
    }
  },
  created() {
    console.log('DriverProcessView')
    this.$store.dispatch('app/getDriverBooking', this.bookingID)
    return
  },
  methods: {
    formatDate(data) {
      // console.log(data)
      const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];      
      return String(data.day) +' '+ monthNames[data.month-1]+' '+ String(data.year)      
    },
    GoToBackProjectsListPage() {
      this.$router.go(-1)
    },     
  },
}
</script>
