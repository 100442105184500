<template>
  <div :class="buttonContainerCss">
    <b-button
      type="submit"
      @click="ProcessBooking"
    >
      {{ buttonLabel }}
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { bookingsMethods } from '@/components/ui/BookingsFunctions'
import { messageBox } from '@/components/ui/MessageBox'
import swal from 'sweetalert2'

export default {
  name: 'DriverProcessButton',
  components: {
    BButton,
  },
  props: {
    isMap: {
      type: Boolean,
      required: true,
    },
    longitude: {
      type: Number,
      required: false,
    },
    latitude: {
      type: Number,
      required: false,
    },
  },
  computed: {
    ...mapGetters({ bookingID: 'app/getDriverBookingID' }),
    ...mapGetters({ currentBooking: 'app/getCurrentBookingData' }),
    buttonContainerCss() {
      // Only if checked in do we show checked out style
      if (this.checkedInStatus === 'in') return 'oci-check-out-button-container'

      return 'oci-check-in-button-container'
    },
    buttonLabel() {
      console.log('subHeading', this.currentBooking.status)
      if (this.currentBooking.status == 'to_do') {
        return 'Start'
      } else if (this.currentBooking.status == 'started'){
        return 'Finish'
      } else if (this.currentBooking.status == 'completed'){
        return 'View'        
      } else if (this.currentBooking.status == 'rejected'){
        return 'Rejected'        
      } else {
        return 'View'        
      }      
    },    
  },
  methods: {
    showWarningMessage(msgDescription, msgTitle) {
      swal.fire({
        title: msgTitle,
        text: msgDescription,
        icon: 'warning',
        confirmButtonText: 'Close X',
        customClass: {
          confirmButton: 'btn oci-close-button',
        },        
        buttonsStyling: false,
      })
    },  
    ProcessBooking() {
      bookingsMethods.checkBookingDetails(this.bookingID, this.currentBooking.status, this.currentBooking.reason, this.currentBooking.reason_notes, this.currentBooking.type, this.currentBooking.booked_for)
      return
    }, 
  },
}
</script>
