<template>
  <div class="oci-check-in-out-body"> 
    <b-card class="oci-check-in-out-card">     
        <b-row>      
          <b-col cols="4">
            Job:
          </b-col>
          <b-col>
          {{currentBooking.job}}
          </b-col>
        </b-row>       
        <b-row>
          <b-col cols="4">
            Type:
          </b-col>
          <b-col>
            <div v-if="currentBooking.type == 'Waste'" style="display: flex; align-items: center;">
              <feather-icon 
                id="type" 
                icon="TrashIcon" 
                size="14" 
                style="stroke-width: 3;"
              />
              &nbsp;{{currentBooking.type}}
            </div>
            <div v-if="currentBooking.type == 'Delivery / Collection'" style="display: flex; align-items: center;">
              &nbsp;
              <feather-icon 
                id="type" 
                icon="TruckIcon" 
                size="14" 
                style="stroke-width: 3;"
              />
              &nbsp;{{currentBooking.type}}
            </div>                        
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            Time:
          </b-col>
          <b-col>
          {{formatTime(currentBooking.booked_for)}}
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            Client:
          </b-col>
          <b-col>
          {{currentBooking.client_name}}
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            Address:
          </b-col>
          <b-col>
          {{currentBooking.site_main_address}}
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            Contact:
          </b-col>
          <b-col>
            {{ currentBooking.contact_name }}<br>
            <b-link :href="getPhoneLink(currentBooking.contact_phone_or_mobile)">
              {{ currentBooking.contact_phone_or_mobile }}
            </b-link>              
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            PO:
          </b-col>
          <b-col>   
          {{currentBooking.po_number}}         
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            Notes:
          </b-col>
          <b-col>       
          {{currentBooking.notes}}     
          </b-col>
        </b-row>                
    </b-card>

    <DriverProcessButton :is-map="false"/>
    <!--<RejectButton :is-map="false" v-if="showButton" />-->
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BButton, BLink,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import DriverProcessButton from '@/components/ui/DriverProcessButton.vue'
import RejectButton from '@/components/ui/RejectButton.vue'
import store from '@/store'
import router from '@/router'

export default {
  name: 'DriverBooking',
  components: {
    DriverProcessButton,
    RejectButton,
    BRow,
    BCol,
    BCard,
    BButton,
    BLink,
  },
  computed: {
    ...mapGetters({ isLoadingBooking: 'app/getIsLoadingBooking' }),
    ...mapGetters({ userName: 'app/getUserName' }),
    ...mapGetters({ bookingID: 'app/getDriverBookingID' }),
    ...mapGetters({ currentBooking: 'app/getCurrentBookingData' }),
  },  
  data() {
    return {
      
    }
  },  
  created() {    
    if (this.currentBooking.status === 'rejected'){
      this.showButton = false;
    } else {
      this.showButton = true;
    }
  }, 
  methods: {
    getPostCodeLink(postCode) {
      return `https://maps.google.com/maps?q=${postCode}`
    },
    getPhoneLink(phoneNo) {
      return `tel:${phoneNo}`
    },
    GoToBackProjectsListPage() {
      // store.commit('app/setUserAssignmentSet', false)
      store.commit('app/changeUserAssignmentsID', this.previousAssignmentsID)
      this.$router.go(-1)
    },
    formatTime(data) {    
        const formattedMinute = String(data.minute).padStart(2, '0');
        return `${data.hour}:${formattedMinute}`;
    },     
  },
}
</script>
